import { useMemo } from 'react';
import { useProfileV2 } from '#lib/Profile';

const useIsDanishIntermediary = () => {
  const { profile } = useProfileV2();
  const isDanishIntermediary = useMemo(() => {
    if (!profile) {
      return false;
    }

    const partnersNotDanishIntermediary = profile.permissions?.businessPartners.filter(
      (partner) => {
        const baseId = getBaseId(partner.id);

        // Check if the base business partner's id is blacklisted
        if (danishBlacklist.includes(baseId)) {
          return false;
        }

        // Check if any expanded partners' base IDs are blacklisted
        if (
          partner.expandedPartners?.some((expandedId) =>
            danishBlacklist.includes(getBaseId(expandedId)),
          )
        ) {
          return false;
        }

        return true;
      },
    );

    return partnersNotDanishIntermediary?.length === 0;
  }, [profile]);

  return isDanishIntermediary;
};

export default useIsDanishIntermediary;

const getBaseId = (id: string) => id.split('_').slice(0, 2).join('_');

const danishBlacklist = [
  '555_100122',
  '555_100170',
  '555_100428',
  '555_100804',
  '555_100804',
  '555_101310',
  '555_102614',
  '555_102729',
  '555_102832',
  '555_103335',
  '555_103815',
  '555_104564',
  '555_104655',
  '555_104952',
  '555_105065',
  '555_105841',
  '555_108196',
  '555_108331',
  '555_109680',
  '555_109789',
  '555_110371',
  '555_112201',
  '555_112788',
  '555_112879',
  '555_113022',
  '555_113803',
  '555_113969',
  '555_114205',
  '555_114237',
  '555_115050',
  '555_115709',
  '555_119252',
  '555_119342',
  '555_120270',
  '555_120615',
  '555_165291',
  '555_165353',
  '555_166207',
  '555_177232',
  '555_177728',
  '555_202773',
  '555_205264',
  '555_207409',
  '555_207478',
  '555_207514',
  '555_207514',
  '555_207944',
  '555_210565',
  '555_210922',
  '555_211129',
  '555_211139',
  '555_220502',
  '555_220722',
  '555_254252',
  '555_300010',
  '555_300295',
  '555_300304',
  '555_300696',
  '555_300807',
  '555_301500',
  '555_301518',
  '555_301727',
  '555_301815',
  '555_302546',
  '555_302755',
  '555_336040',
  '555_336040',
  '555_336172',
  '555_338228',
  '555_338472',
  '555_338686',
  '555_338764',
  '555_338858',
  '555_339022',
  '555_339747',
  '555_340378',
  '555_340474',
  '555_341234',
  '555_341640',
  '555_342290',
  '555_343649',
  '555_343906',
  '555_344327',
  '555_344742',
  '555_344844',
  '555_344945',
  '555_345039',
  '555_346098',
  '555_346260',
  '555_346986',
  '555_347321',
  '555_347337',
  '555_347632',
  '555_347649',
  '555_347847',
  '555_348334',
  '555_348567',
  '555_349596',
  '555_349637',
  '555_349668',
  '555_349928',
  '555_350448',
  '555_350453',
  '555_350652',
  '555_350682',
  '555_351308',
  '555_351778',
  '555_352319',
  '555_352870',
  '555_352926',
  '555_353084',
  '555_353329',
  '555_353357',
  '555_353394',
  '555_353539',
  '555_353553',
  '555_353882',
  '555_354225',
  '555_354639',
  '555_354897',
  '555_355117',
  '555_355242',
  '555_355275',
  '555_355848',
  '555_355923',
  '555_355960',
  '555_356447',
  '555_356454',
  '555_357160',
  '555_357244',
  '555_357428',
  '555_357495',
  '555_357511',
  '555_357679',
  '555_357937',
  '555_358302',
  '555_358333',
  '555_358917',
  '555_358929',
  '555_358983',
  '555_359116',
  '555_359128',
  '555_359198',
  '555_359307',
  '555_359454',
  '555_359478',
  '555_359700',
  '555_359900',
  '555_360080',
  '555_360131',
  '555_360253',
  '555_360387',
  '555_360706',
  '555_361323',
  '555_361420',
  '555_361988',
  '555_362157',
  '555_362178',
  '555_362580',
  '555_363011',
  '555_363103',
  '555_363241',
  '555_363297',
  '555_363556',
  '555_363857',
  '555_364101',
  '555_364357',
  '555_364549',
  '555_364666',
  '555_364880',
  '555_365365',
  '555_365683',
  '555_365705',
  '555_366280',
  '555_366398',
  '555_366437',
  '555_366614',
  '555_366617',
  '555_366620',
  '555_366691',
  '555_366722',
  '555_366745',
  '555_366896',
  '555_367106',
  '555_367114',
  '555_367231',
  '555_367296',
  '555_367429',
  '555_367552',
  '555_367692',
  '555_368006',
  '555_368041',
  '555_368141',
  '555_368555',
  '555_368799',
  '555_369390',
  '555_369520',
  '555_369593',
  '555_369693',
  '555_369812',
  '555_369882',
  '555_369961',
  '555_370012',
  '555_370210',
  '555_370221',
  '555_370277',
  '555_370352',
  '555_370430',
  '555_370504',
  '555_370534',
  '555_370788',
  '555_370789',
  '555_370862',
  '555_370996',
  '555_371086',
  '555_371266',
  '555_371308',
  '555_371363',
  '555_371367',
  '555_371558',
  '555_371558',
  '555_371614',
  '555_371761',
  '555_371763',
  '555_372054',
  '555_372228',
  '555_372248',
  '555_372313',
  '555_372811',
  '555_372811',
  '555_372828',
  '555_373137',
  '555_373210',
  '555_373218',
  '555_373376',
  '555_373836',
  '555_390591',
  '555_390623',
  '555_390643',
  '555_390690',
  '555_390711',
  '555_390737',
  '555_390760',
  '555_390840',
  '555_390850',
  '555_390909',
  '555_390925',
  '555_390925',
  '555_391023',
  '555_391023',
  '555_392040',
  '555_392470',
  '555_392598',
  '555_392652',
  '555_392658',
  '555_392673',
  '555_397042',
  '555_397116',
  '555_397149',
  '555_397153',
  '555_397153',
  '555_397209',
  '555_397226',
  '555_401115',
  '555_401250',
  '555_401284',
  '555_401446',
  '555_401484',
  '555_410328',
  '555_410361',
  '555_410402',
  '555_410433',
  '555_410577',
  '555_410587',
  '555_410632',
  '555_410774',
  '555_410825',
  '555_410857',
  '555_411001',
  '555_411012',
  '555_411439',
  '555_411491',
  '555_411714',
  '555_413881',
  '555_425014',
  '555_425275',
  '555_426169',
  '555_426504',
  '555_427061',
  '555_427540',
  '555_427782',
  '555_454093',
  '555_458165',
  '555_459232',
  '555_460110',
  '555_463113',
  '555_464044',
  '555_467218',
  '555_468238',
  '555_472130',
  '555_480228',
  '555_480562',
  '555_484291',
  '555_484351',
  '555_484487',
  '555_484762',
  '555_486277',
  '555_486874',
  '555_487710',
  '555_488211',
  '555_501494',
  '555_502580',
  '555_511188',
  '555_720803',
  '555_750305',
  '555_750554',
  '555_750758',
  '555_760089',
  '555_760096',
  '555_760101',
  '555_760151',
  '555_760158',
  '555_760182',
  '555_760294',
  '555_760296',
  '555_760299',
  '555_760506',
  '555_760707',
  '555_760714',
  '555_760849',
  '555_760912',
  '555_801118',
  '555_801118',
  '555_801120',
  '555_801128',
  '555_804002',
  '555_804043',
  '555_805226',
  '555_805291',
  '555_805557',
];
